import { gql, useQuery } from "@apollo/client";
import { Alert, Card, Table } from "antd";
import { formatISO, isEqual } from "date-fns";

export const QUERY = gql`
    query GetLaundryData($dates: [Date]) {
        cleaningGroups(filter: {dates: $dates}) {
            id
            date
            apartments {
                id
                numberOfGuests
                storage {
                    id
                }
            }
        }
        storages {
            id
            name
            order
        }
    }
`;

export default function LaundryTable(props) {
    const { dates } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            dates: dates,
        }
    });

    if (loading) {
        return (
            <Card loading />
        )
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load laundry data"
            />
        );
    }

    function getGuestsCount(cleaningGroups, date, storageId) {
        const result = cleaningGroups
            .filter(cleaningGroup => isEqual(cleaningGroup.date, date))
            .map(cleaningGroup => {
                return cleaningGroup.apartments
                    .filter(apartment => apartment.storage.id === storageId)
                    .map(apartment => apartment.numberOfGuests)
                    .reduce((a, b) => a + b, 0);
            })
            .reduce((a, b) => a + b, 0);

        console.log(date, storageId, result);

        return result;
    }

    const storages = [...data.storages ?? []]
        .sort((a, b) => a.order - b.order)
        .map(storage => {
            return {
                key: storage.id,
                storage: storage.name,
                ...Object.fromEntries(
                    dates.map(date => ([
                        formatISO(date, { representation: 'date' }),
                        getGuestsCount(data.cleaningGroups, date, storage.id),
                    ]))
                ),
            };
        })

    return (
        <Table
            dataSource={storages}
            columns={[
                {
                    title: 'Kantorek',
                    dataIndex: 'storage',
                },
                ...dates.map(date => ({
                    title: formatISO(date, { representation: 'date' }),
                    dataIndex: formatISO(date, { representation: 'date' }),
                    align: 'right',
                }))
            ]}
            rowKey="key"
            pagination={false}
            sticky={true}
        />
    )
}
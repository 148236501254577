import { useState } from 'react';
import { PageHeader } from "antd";
import { addDays, startOfToday } from "date-fns";
import DateSelectWithArrows from "components/date-select-with-arrows/DateSelectWithArrows";
import LaundryTable from 'laundry/components/LaundryTable';

export default function LaundryView() {
    const [date, setDate] = useState(startOfToday());

    const dates = [
        addDays(date, 0),
        addDays(date, 1),
        addDays(date, 2),
        addDays(date, 3),
    ];

    return (
        <PageHeader
            title="Pralnia"
            extra={
                <DateSelectWithArrows
                    value={date}
                    onChange={value => setDate(value)}
                />
            }
        >
            <LaundryTable
                dates={dates}
            />
        </PageHeader>
    )
}
import { CopyOutlined, HistoryOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, Menu, Row, Select, Space, Typography } from "antd";
import { useState } from "react";

export default function PriceConfigModificationPanel(props) {
    const { weekdayDeltas, onAction, onChangesModalOpen } = props;

    const [minPrice, setMinPrice] = useState(0);
    const [delta, setDelta] = useState(0);

    function handleminPriceIncrease() {
        onAction({
            action: 'minPriceIncrease',
            amount: parseInt(minPrice),
        });
    }

    function handleminPriceIncreasePercent() {
        onAction({
            action: 'minPriceIncreasePercent',
            amount: parseInt(minPrice),
        });
    }

    function handleminPriceDecrease() {
        onAction({
            action: 'minPriceDecrease',
            amount: parseInt(minPrice),
        });
    }

    function handleminPriceDecreasePercent() {
        onAction({
            action: 'minPriceDecreasePercent',
            amount: parseInt(minPrice),
        });
    }

    function handleminPriceSet() {
        onAction({
            action: 'minPriceSet',
            amount: parseInt(minPrice),
        });
    }

    function handleminPriceSetWeekdayDelta(weekdayDeltaId) {
        onAction({
            action: 'minPriceSetWeekdayDelta',
            amount: parseInt(minPrice),
            weekdayDeltas: weekdayDeltas.find(weekdayDelta => weekdayDelta.id === weekdayDeltaId)?.weekdayDeltas,
        });
    }

    function handleDeltaIncrease() {
        onAction({
            action: 'deltaIncrease',
            amount: parseInt(delta),
        });
    }

    function handleDeltaIncreasePercent() {
        onAction({
            action: 'deltaIncreasePercent',
            amount: parseInt(delta),
        });
    }

    function handleDeltaDecrease() {
        onAction({
            action: 'deltaDecrease',
            amount: parseInt(delta),
        });
    }

    function handleDeltaDecreasePercent() {
        onAction({
            action: 'deltaDecreasePercent',
            amount: parseInt(delta),
        });
    }

    function handleDeltaSet() {
        onAction({
            action: 'deltaSet',
            amount: parseInt(delta),
        });
    }

    function handleSlopeSet(slope) {
        onAction({
            action: 'slopeSet',
            amount: parseInt(slope),
        });
    }

    function handleCopy() {
        onAction({
            action: 'copy',
        });
    }

    function handlePaste() {
        onAction({
            action: 'paste',
        });
    }

    return (
        <Row gutter={[16, 16]}>
            <Col>
                <Button
                    icon={<HistoryOutlined />}
                    onClick={() => onChangesModalOpen()}
                    size="small"
                >
                    Changes
                </Button>
            </Col>
            <Col>
                <Space>
                    <Typography.Text>
                        Min price
                    </Typography.Text>
                    <Input.Group compact>
                        <Input
                            value={minPrice}
                            onChange={e => setMinPrice(e.target.value)}
                            size="small"
                            style={{
                                width: '70px',
                            }}
                        />
                        <Button
                            icon="+"
                            onClick={() => handleminPriceIncrease()}
                            size="small"
                        />
                        <Button
                            icon="+%"
                            onClick={() => handleminPriceIncreasePercent()}
                            size="small"
                        />
                        <Button
                            icon="-"
                            onClick={() => handleminPriceDecrease()}
                            size="small"
                        />
                        <Button
                            icon="-%"
                            onClick={() => handleminPriceDecreasePercent()}
                            size="small"
                        />
                        <Button
                            icon="="
                            onClick={() => handleminPriceSet()}
                            size="small"
                        />
                        <Dropdown
                            overlay={
                                <Menu
                                    items={
                                        weekdayDeltas
                                            .map(weekdayDelta => ({
                                                key: weekdayDelta.id,
                                                label: weekdayDelta.name,
                                            }))
                                    }
                                    onClick={({ key: weekdayDeltaId }) => handleminPriceSetWeekdayDelta(weekdayDeltaId)}
                                />
                            }
                        >
                            <Button
                                icon="WD"
                                size="small"
                            />
                        </Dropdown>
                    </Input.Group>
                </Space>
            </Col>
            <Col>
                <Space>
                    <Typography.Text>
                        Delta
                    </Typography.Text>
                    <Input.Group compact>
                        <Input
                            value={delta}
                            onChange={e => setDelta(e.target.value)}
                            placeholder="Delta"
                            size="small"
                            style={{
                                width: '70px',
                            }}
                        />
                        <Button
                            icon="+"
                            onClick={() => handleDeltaIncrease()}
                            size="small"
                        />
                        <Button
                            icon="+%"
                            onClick={() => handleDeltaIncreasePercent()}
                            size="small"
                        />
                        <Button
                            icon="-"
                            onClick={() => handleDeltaDecrease()}
                            size="small"
                        />
                        <Button
                            icon="-%"
                            onClick={() => handleDeltaDecreasePercent()}
                            size="small"
                        />
                        <Button
                            icon="="
                            onClick={() => handleDeltaSet()}
                            size="small"
                        />
                    </Input.Group>
                </Space>
            </Col>
            <Col>
                <Select
                    placeholder="Slope"
                    options={[
                        { value: 30, label: '30' },
                        { value: 35, label: '35' },
                        { value: 40, label: '40' },
                        { value: 45, label: '45' },
                        { value: 50, label: '50' },
                        { value: 60, label: '60' },
                        { value: 70, label: '70' },
                        { value: 80, label: '80' },
                        { value: 90, label: '90' },
                        { value: 100, label: '100' },
                    ]}
                    onChange={value => handleSlopeSet(value)}
                    size="small"
                />
            </Col>
            <Col>
                <Space>
                    <Button
                        icon={<CopyOutlined />}
                        onClick={() => handleCopy()}
                        size="small"
                    >
                        Copy
                    </Button>
                    <Button
                        icon={<SnippetsOutlined />}
                        onClick={() => handlePaste()}
                        size="small"
                    >
                        Paste
                    </Button>
                </Space>
            </Col>
        </Row>
    );
}
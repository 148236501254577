import { Anchor, Button, Card, Col, message, Modal, PageHeader, Row, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { CREATE_ADJUST_PRICES_TASK, GET_APARTMENT_PRICE_CONFIG_CHANGES_QUERY, GET_APARTMENT_PRICE_CONFIG_QUERY, UPDATE_APARTMENT_PRICE_CONFIGS_MUTATION } from "prices/graphql";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from 'react-router-dom';

import { useCallback, useState } from "react";
import { useAuth } from "auth";
import { addDays, differenceInDays, formatDistanceToNow, formatISO, getDay, isEqual, max, min, parseISO, startOfToday, startOfWeek } from "date-fns";
import PriceConfigModificationPanel from "prices/PriceConfigModificationPanel";
import PriceConfigCalendar from "prices/PriceConfigCalendar";
import PriceConfigChangesList from "./PriceConfigChangesList";
import { useWaitForTask } from "common/task";
import errorHandler from "common/errorHandler";

export default function PriceConfigView() {
    const { apartmentId } = useParams();
    const navigate = useNavigate();
    const auth = useAuth();

    const [selectedDays, setSelectedDays] = useState([]);
    const [changedMinPrices, setChangedMinPrices] = useState([]);
    const [changedDeltas, setChangedDeltas] = useState([]);
    const [changedSlopes, setChangedSlopes] = useState([]);

    const dateFrom = startOfWeek(startOfToday())

    const {
        data: apartmentPriceConfigData,
        loading: apartmentPriceConfigLoading,
        refetch: apartmentPriceConfigRefetch,
    } = useQuery(GET_APARTMENT_PRICE_CONFIG_QUERY, {
        variables: {
            apartmentId,
            dateFrom,
        },
    });

    const [loadApartmentPriceConfigChanges] = useLazyQuery(GET_APARTMENT_PRICE_CONFIG_CHANGES_QUERY);

    const [updateApartmentPriceConfigs] = useMutation(UPDATE_APARTMENT_PRICE_CONFIGS_MUTATION);

    const [
        createAdjustPricesTask, {
            data: createAdjustPricesTaskData,
            loading: createAdjustPricesTaskLoading,
        },
    ] = useMutation(CREATE_ADJUST_PRICES_TASK, {
        onCompleted(response) {
            if (response.createAdjustPricesTask.error) {
                errorHandler(response.createAdjustPricesTask.error);
            }
        }
    });

    const handleAdjustPricesTaskCompleted = useCallback(task => {
        if (task.status === 'finished') {
            message.success('Prices recalculated');
            apartmentPriceConfigRefetch();
            setChangedMinPrices([]);
            setChangedDeltas([]);
            setChangedSlopes([]);
        }
    }, [apartmentPriceConfigRefetch]);

    const { loading: adjustPricesTaskLoading } = useWaitForTask(createAdjustPricesTaskData?.createAdjustPricesTask?.task?.id, {
        onCompleted: handleAdjustPricesTaskCompleted,
    });

    function handleDaysSelected(days) {
        const allDaysSelected = days
            .every(day => selectedDays.some(selectedDay => isEqual(selectedDay, day)));

        if (allDaysSelected) {
            setSelectedDays(
                selectedDays
                    .filter(selectDay => !days.some(day => isEqual(day, selectDay)))
            );
        }
        else {
            setSelectedDays(
                [...selectedDays, ...days]
                    .filter((value, index, array) => array.findIndex(element => isEqual(element, value)) === index)
            );
        }
    }

    function handleRecalculate() {
        createAdjustPricesTask({
            variables: {
                input: {
                    apartmentId,
                },
            },
        });
    }

    function handleAction(action) {
        const selectedPriceConfigs = [...apartmentPriceConfigData.apartment.priceConfigs ?? []]
            .filter(priceConfig => selectedDays.some(selectedDay => isEqual(selectedDay, priceConfig.date)));

        let createOperations = [];
        let updateOperations = [];
        if (action.action === 'minPriceIncrease') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    minPrice: priceConfig.minPrice + action.amount,
                }));
        }
        if (action.action === 'minPriceIncreasePercent') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    minPrice: Math.round(priceConfig.minPrice + (priceConfig.minPrice * (action.amount / 100))),
                }));
        }
        if (action.action === 'minPriceDecrease') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    minPrice: priceConfig.minPrice - action.amount,
                }));
        }
        if (action.action === 'minPriceDecreasePercent') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    minPrice: Math.round(priceConfig.minPrice - (priceConfig.minPrice * (action.amount / 100))),
                }));
        }
        if (action.action === 'minPriceSet') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    minPrice: action.amount,
                }));
        }
        if (action.action === 'minPriceSetInplace') {
            updateOperations = [{
                apartmentPriceConfigId: action.priceConfigId,
                date: action.date,
                minPrice: action.amount,
            }];
        }
        if (action.action === 'minPriceSetWeekdayDelta') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    minPrice: action.amount + action.weekdayDeltas[getDay(priceConfig.date)],
                }));
        }
        if (action.action === 'deltaIncrease') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    delta: priceConfig.delta + action.amount,
                }));
        }
        if (action.action === 'deltaIncreasePercent') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    delta: priceConfig.delta + (priceConfig.delta * (action.amount / 100)),
                }));
        }
        if (action.action === 'deltaDecrease') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    delta: priceConfig.delta - action.amount,
                }));
        }
        if (action.action === 'deltaDecreasePercent') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    delta: priceConfig.delta - (priceConfig.delta * (action.amount / 100)),
                }));
        }
        if (action.action === 'deltaSet') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    delta: action.amount,
                }));
        }
        if (action.action === 'deltaSetInplace') {
            updateOperations = [{
                apartmentPriceConfigId: action.priceConfigId,
                date: action.date,
                delta: action.amount,
            }];
        }
        if (action.action === 'slopeSet') {
            updateOperations = selectedPriceConfigs
                .map(priceConfig => ({
                    apartmentPriceConfigId: priceConfig.id,
                    date: priceConfig.date,
                    slope: action.amount,
                }));
        }
        if (action.action === 'slopeSetInplace') {
            updateOperations = [{
                apartmentPriceConfigId: action.priceConfigId,
                date: action.date,
                slope: action.amount,
            }];
        }
        if (action.action === 'copy') {
            window.localStorage.setItem(
                'copiedPriceConfigs',
                JSON.stringify(
                    selectedPriceConfigs
                        .map(selectedPriceConfig => ({
                            id: selectedPriceConfig.id,
                            date: formatISO(selectedPriceConfig.date, { representation: 'date' }),
                            minPrice: selectedPriceConfig.minPrice,
                            delta: selectedPriceConfig.delta,
                            slope: selectedPriceConfig.slope,
                        }))
                ),
            );
            setSelectedDays([]);
        }
        if (action.action === 'paste') {
            const copiedPriceConfigs = JSON.parse(window.localStorage.getItem('copiedPriceConfigs'))
                .map(copiedPriceConfig => ({
                    id: copiedPriceConfig.id,
                    date: parseISO(copiedPriceConfig.date),
                    minPrice: copiedPriceConfig.minPrice,
                    delta: copiedPriceConfig.delta,
                    slope: copiedPriceConfig.slope,
                }));

            const firstDayToCopy = min(copiedPriceConfigs.map(copiedPriceConfig => copiedPriceConfig.date));
            const firstDayToPaste = min(selectedDays);

            const daysDiff = differenceInDays(firstDayToPaste, firstDayToCopy);

            const newSelectedDays = [];
            updateOperations = copiedPriceConfigs
                .filter(copiedPriceConfig => {
                    const dateToPaste = addDays(copiedPriceConfig.date, daysDiff);
                    return [...apartmentPriceConfigData.apartment.priceConfigs ?? []]
                        .find(priceConfig => isEqual(priceConfig.date, dateToPaste));
                })
                .map(copiedPriceConfig => {
                    const dateToPaste = addDays(copiedPriceConfig.date, daysDiff);
                    const pastedPriceConfig = [...apartmentPriceConfigData.apartment.priceConfigs ?? []]
                        .find(priceConfig => isEqual(priceConfig.date, dateToPaste));
                    newSelectedDays.push(dateToPaste);
                    return {
                        apartmentPriceConfigId: pastedPriceConfig.id,
                        date: pastedPriceConfig.date,
                        minPrice: copiedPriceConfig.minPrice,
                        delta: copiedPriceConfig.delta,
                        slope: copiedPriceConfig.slope,
                    };
                });

            setSelectedDays(newSelectedDays);
        }
        if (action.action === 'addPriceConfigs') {
            const dates = [...apartmentPriceConfigData?.apartment?.priceConfigs ?? []]
                .map(priceConfig => priceConfig.date);

            const maxDate = dates.length > 0
                ? max(dates)
                : startOfWeek(startOfToday());

            createOperations = [1, 2, 3, 4, 5, 6, 7]
                .map(offset => ({
                    apartmentId,
                    date: addDays(maxDate, offset),
                    minPrice: 100,
                    delta: 100,
                    slope: 60,
                    gapPenalties: [],
                }));
        }

        if (updateOperations.length > 0) {
            setChangedMinPrices([
                ...changedMinPrices,
                ...updateOperations
                    .filter(operation => operation.minPrice)
                    .map(operation => operation.apartmentPriceConfigId)
            ]);
            setChangedDeltas([
                ...changedDeltas,
                ...updateOperations
                    .filter(operation => operation.delta)
                    .map(operation => operation.apartmentPriceConfigId)
            ]);
            setChangedSlopes([
                ...changedSlopes,
                ...updateOperations
                    .filter(operation => operation.slope)
                    .map(operation => operation.apartmentPriceConfigId)
            ]);
            updateApartmentPriceConfigs({
                variables: {
                    input: {
                        apartmentId,
                        changes: updateOperations.map(operation => ({
                            date: operation.date,
                            ...operation.minPrice ? { minPrice: Math.round(operation.minPrice) } : {},
                            ...operation.delta ? { delta: Math.round(operation.delta) } : {},
                            ...operation.slope ? { slope: operation.slope } : {},
                            ...operation.gapPenalties ? { gapPenalties: operation.gapPenalties } : {},
                        })),
                    },
                },
                optimisticResponse: {
                    updateApartmentPriceConfigs: {
                        error: null,
                        apartmentPriceConfigs: updateOperations.map(operation => ({
                            ...apartmentPriceConfigData.apartment.priceConfigs.find(priceConfig => priceConfig.id === operation.apartmentPriceConfigId),
                            ...operation,
                            id: operation.apartmentPriceConfigId,
                            __typename: "ApartmentPriceConfig",
                        })),
                        recalculateApartmentCalendarTask: {
                            id: null,
                        }
                    },
                },
                update(cache) {
                    window.setTimeout(() => {
                        cache.evict({
                            id: cache.identify({
                                __typename: 'Apartment',
                                id: apartmentId,
                            }),
                            fieldName: 'calendar',
                        });
                        setChangedMinPrices([]);
                        setChangedDeltas([]);
                        setChangedSlopes([]);
                    }, 3000);
                }
            })
        }
        if (createOperations.length > 0) {
            updateApartmentPriceConfigs({
                variables: {
                    input: {
                        apartmentId,
                        changes: createOperations,
                    },
                },
                update(cache) {
                    cache.evict({
                        id: cache.identify({ __typename: 'Apartment', id: apartmentId }),
                        fieldName: 'priceConfigs',
                    });
                    window.setTimeout(() => {
                        cache.evict({
                            id: cache.identify({
                                __typename: 'Apartment',
                                id: apartmentId,
                            }),
                            fieldName: 'calendar',
                        })
                    }, 3000);
                },
            });
        }
    }

    function handleChangesModalOpen() {
        if (selectedDays.length === 1) {
            const apartmentPriceConfigs = [...apartmentPriceConfigData?.apartment?.priceConfigs ?? []]
                .filter(priceConfig => selectedDays.some(selectedDay => isEqual(selectedDay, priceConfig.date)));

            const apartmentPriceConfig = apartmentPriceConfigs[0]
            const apartmentPriceConfigId = apartmentPriceConfig.id;

            loadApartmentPriceConfigChanges({
                variables: {
                    apartmentPriceConfigId,
                },
                fetchPolicy: 'network-only',
            })
                .then(({ data }) => {
                    Modal.info({
                        title: `Changes on ${formatISO(apartmentPriceConfig.date, { representation: 'date' })}`,
                        content: (
                            <PriceConfigChangesList
                                changes={data.apartmentPriceConfig.changes}
                            />
                        ),
                        width: 600,
                    });
                });
        }
    }

    const extra = []
    if (auth.user?.permissions?.includes('task:create')) {
        if (apartmentPriceConfigData?.apartment?.adjustPricesTasks?.[0]) {
            extra.push(
                <Typography.Text
                    key="lastSynchronized"
                >
                    Last synchronized {formatDistanceToNow(apartmentPriceConfigData.apartment.adjustPricesTasks?.[0]?.finishedAt, { addSuffix: true })}
                </Typography.Text>
            )
        }
        extra.push(
            <Button
                icon={<SyncOutlined />}
                type="primary"
                onClick={() => handleRecalculate()}
                key="recalculate"
                loading={createAdjustPricesTaskLoading || adjustPricesTaskLoading}
            >
                Recalculate
            </Button>
        );
    }

    return (
        <PageHeader
            title={apartmentPriceConfigData?.apartment?.name}
            onBack={() => navigate(-1)}
            extra={extra}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Anchor>
                        <Card
                            bodyStyle={{
                                padding: '16px',
                            }}
                        >
                            <Row align="end">
                                <Col>
                                    <PriceConfigModificationPanel
                                        weekdayDeltas={[...apartmentPriceConfigData?.apartmentPriceConfigWeekdayDeltas ?? []]}
                                        onAction={action => handleAction(action)}
                                        onChangesModalOpen={() => handleChangesModalOpen()}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Anchor>
                </Col>
                <Col span={24}>
                    <Card
                        loading={apartmentPriceConfigLoading}
                        bodyStyle={{
                            padding: '16px',
                        }}
                    >
                        <PriceConfigCalendar
                            priceConfigs={[...apartmentPriceConfigData?.apartment?.priceConfigs ?? []]}
                            calendar={[...apartmentPriceConfigData?.apartment?.calendar ?? []]}
                            selectedDays={selectedDays}
                            changedMinPrices={changedMinPrices}
                            changedDeltas={changedDeltas}
                            changedSlopes={changedSlopes}
                            onDaysSelected={days => handleDaysSelected(days)}
                            onAction={action => handleAction(action)}
                        />
                    </Card>
                </Col>
            </Row>
        </PageHeader >
    )
}
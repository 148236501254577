import { AuthProvider, useAuth } from 'auth';
import { Layout } from 'antd';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { apolloClient } from 'apollo';
import { ApolloProvider } from '@apollo/client';
import { NotificationsProvider } from 'notifications';
import { useState } from 'react';
import CalendarView from 'calendar/views/CalendarView';
import CreateApartmentView from 'apartments/views/CreateApartmentView';
import CreateJobView from 'jobs-new/views/CreateJobView';
import CreateOrganizationView from 'organizations/views/CreateOrganizationView';
import CreateReservationView from 'reservations/views/CreateReservationView';
import CreateStorageView from 'storages/views/CreateStorageView';
import CreateUserGroupView from 'user-groups/views/CreateUserGroupView';
import CreateUserView from 'users/views/CreateUserView';
import DisplayReservationView from 'reservations/views/DisplayReservationView';
import HomeView from 'home/HomeView';
import ListJobsView from 'jobs-new/views/ListJobsView';
import JobsView from 'jobs/JobsView';
import ShowJobView from 'jobs-new/views/ShowJobView';
import LaundryView from 'laundry/views/LandryView';
import ListApartmentsView from 'apartments/views/ListApartmentsView';
import ListOrganizationsView from 'organizations/views/ListOrganizationsView';
import ListStoragesView from 'storages/views/ListStoragesView';
import ListUserGroupsView from 'user-groups/views/ListUserGroupsView';
import LoginView from 'login/LoginView';
import MainMenu from 'MainMenu';
import MapApartmentView from 'apartments/views/MapApartmentView';
import MessagesView from 'messages/MessagesView';
import NewReservationsView from 'reservations/NewReservationsView';
import OnboardingView from 'onboarding/OnboardingView';
import PriceConfigsView from 'prices/PriceConfigsView';
import PriceConfigView from 'prices/PriceConfigView';
import SearchReservationsView from 'reservations/views/SearchReservationsView';
import Statistics100DaysComparison from 'statistics/custom/Statistics100DaysComparison';
import Statistics100DaysComparison30Days from 'statistics/custom/Statistics100DaysComparison30Days';
import StatisticsView from 'statistics/StatisticsView';
import StatisticsViewsListView from 'statistics/StatisticsViewsListView';
import UpdateApartmentView from 'apartments/views/UpdateApartmentView';
import UpdateJobView from 'jobs-new/views/UpdateJobView';
import UpdateOrganizationView from 'organizations/views/UpdateOrganizationView';
import UpdateReservationView from 'reservations/views/UpdateReservationView';
import UpdateStorageView from 'storages/views/UpdateStorageView';
import UpdateUserGroupView from 'user-groups/views/UpdateUserGroupView';
import UpdateUserView from 'users/views/UpdateUserView';
import UserBadge from 'users/UserBadge';
import UsersListView from 'users/views/ListUsersView';
import WeekdayDeltasView from 'prices/WeekdayDeltasView';
import ListRecentJobsView from 'jobs-new/views/ListRecentJobsView';
import CreateJobFromPresetView from 'jobs-new/views/CreateJobFromPresetView';
import ViewReservationsSummary from 'cleaning-new/views/ViewReservationsSummary';
import ReviewReservationView from 'cleaning-new/views/ReviewReservationView';
import PlanCleaningRedirect from 'cleaning-new/views/PlanCleaningRedirect';
import PlanCleaningView from 'cleaning-new/views/PlanCleaningView';
import ViewUserAvailabilityView from 'users/views/ViewUserAvailabilityView';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CheckReservationView from 'reservations/views/CheckReservationView';
import DisplayCleaningStatusView from 'cleaning-new/views/DisplayCleaningStatusView';
import DisplayCleaningStatusRedirect from 'cleaning-new/views/DisplayCleaningStatusRedirect';
import ListApartmentExpensesView from 'expenses/views/ListApartmentExpensesView';
import CreateApartmentExpenseView from 'expenses/views/CreateApartmentExpenseView';
import UpdateApartmentExpenseView from 'expenses/views/UpdateApartmentExpenseView';
import DisplayCleaningTimelineRedirect from 'cleaning-new/views/DisplayCleaningTimelineRedirect';
import DisplayCleaningTimelineView from 'cleaning-new/views/DisplayCleaningTimelineView';
import DisplayUserGroupAvailabilityView from 'user-groups/views/DisplayUserGroupAvailabilityView';
import DisplayCleaningAvailabilityView from 'cleaning-new/views/DisplayCleaningAvailabilityView';
import UpdateAutomationView from 'automation/views/UpdateAutomationView';
import ListStayqlQueriesView from 'stayql/views/ListStayqlQueriesView';
import ShowStayqlQueryView from 'stayql/views/ShowStayqlQueryView';
// import CreateApartmentViewNew from 'apartments/views/CreateApartmentViewNew';
import CreateStayqlQueryView from 'stayql/views/CreateStayqlQueryView';
import EditStayqlQueryView from 'stayql/views/EditStayqlQueryView';
// import DesignTilesView from 'tiles/views/DesignTilesView';
// import ShowApartmentView from 'apartments/views/ShowApartmentView';

function App() {
    return (
        <ApolloProvider client={apolloClient}>
            <NotificationsProvider>
                <AuthProvider>
                    <DndProvider backend={HTML5Backend}>
                        <Router>
                            <MainView />
                        </Router>
                    </DndProvider>
                </AuthProvider>
            </NotificationsProvider>
        </ApolloProvider>
    );
}

function MainView() {
    const auth = useAuth();
    const [collapsed, setCollapsed] = useState(false);

    if (!auth.user) {
        return <LoginView />
    }
    if (auth.user.status === 'onboarding') {
        return <OnboardingView />
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {process.env.REACT_APP_BUILD_SHA && process.env.REACT_APP_BUILD_TIMESTAMP && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '0px',
                        left: '0px',
                        zIndex: 99999,
                        fontSize: '10px',
                        padding: '2px 6px',
                        backgroundColor: '#000000',
                        color: '#ffffff',
                    }}
                >
                    {process.env.REACT_APP_BUILD_SHA} {process.env.REACT_APP_BUILD_TIMESTAMP}
                </div>
            )}
            <Layout.Sider
                theme="light"
                collapsible
                collapsed={collapsed}
                onCollapse={(c) => setCollapsed(c)}
                collapsedWidth={48}
            >
                {!collapsed && <UserBadge />}
                <MainMenu
                    collapsed={collapsed}
                />
            </Layout.Sider>
            <Layout.Content>
                <Routes>
                    <Route
                        path="/"
                        element={<HomeView />}
                    />
                    <Route
                        path="/users"
                        element={<UsersListView />}
                    />
                    <Route
                        path="/users/create"
                        element={<CreateUserView />}
                    />
                    <Route
                        path="/users/:userId"
                        element={<UpdateUserView />}
                    />
                    <Route
                        path="/user-groups"
                        element={<ListUserGroupsView />}
                    />
                    <Route
                        path="/user-groups/create"
                        element={<CreateUserGroupView />}
                    />
                    <Route
                        path="/user-groups/availability"
                        element={<DisplayUserGroupAvailabilityView />}
                    />
                    <Route
                        path="/user-groups/availability/:userGroupId"
                        element={<DisplayUserGroupAvailabilityView />}
                    />
                    <Route
                        path="/user-groups/:userGroupId"
                        element={<UpdateUserGroupView />}
                    />
                    <Route
                        path="/organizations"
                        element={<ListOrganizationsView />}
                    />
                    <Route
                        path="/organizations/create"
                        element={<CreateOrganizationView />}
                    />
                    <Route
                        path="/organizations/:organizationId"
                        element={<UpdateOrganizationView />}
                    />
                    <Route
                        path="/apartments"
                        element={<ListApartmentsView />}
                    />
                    <Route
                        path="/apartments/create"
                        element={<CreateApartmentView />}
                    />
                    <Route
                        path="/apartments/:apartmentId/mapping"
                        element={<MapApartmentView />}
                    />
                    <Route
                        path="/apartments/:apartmentId"
                        element={<UpdateApartmentView />}
                    />
                    <Route
                        path="/storages"
                        element={<ListStoragesView />}
                    />
                    <Route
                        path="/storages/create"
                        element={<CreateStorageView />}
                    />
                    <Route
                        path="/storages/:storageId"
                        element={<UpdateStorageView />}
                    />
                    <Route
                        path="/reservations/new"
                        element={<NewReservationsView />}
                    />
                    <Route
                        path="/reservations/create"
                        element={<CreateReservationView />}
                    />
                    <Route
                        path="/reservations/search"
                        element={<SearchReservationsView />}
                    />
                    <Route
                        path="/reservations/:reservationId/update"
                        element={<UpdateReservationView />}
                    />
                    <Route
                        path="/reservations/:reservationId"
                        element={<DisplayReservationView />}
                    />
                    <Route
                        path="/reservations/check/:reservationId"
                        element={<CheckReservationView />}
                    />
                    <Route
                        path="/cleaning/availability"
                        element={<DisplayCleaningAvailabilityView />}
                    />
                    <Route
                        path="/statistics"
                        element={<StatisticsViewsListView />}
                    />
                    <Route
                        path="/statistics/100-days-comparison"
                        element={<Statistics100DaysComparison />}
                    />
                    <Route
                        path="/statistics/100-days-comparison-30-days"
                        element={<Statistics100DaysComparison30Days />}
                    />
                    <Route
                        path="/statistics/:statisticsViewId"
                        element={<StatisticsView />}
                    />
                    <Route
                        path="/expenses"
                        element={<ListApartmentExpensesView />}
                    />
                    <Route
                        path="/expenses/create"
                        element={<CreateApartmentExpenseView />}
                    />
                    <Route
                        path="/expenses/:apartmentExpenseId"
                        element={<UpdateApartmentExpenseView />}
                    />
                    <Route
                        path="/calendar"
                        element={<CalendarView />}
                    />
                    <Route
                        path="/prices"
                        element={<PriceConfigsView />}
                    />
                    <Route
                        path="/prices/weekday-deltas"
                        element={<WeekdayDeltasView />}
                    />
                    <Route
                        path="/prices/:apartmentId"
                        element={<PriceConfigView />}
                    />
                    <Route
                        path="/jobs"
                        element={<JobsView />}
                    />
                    <Route
                        path="/jobs-new/create"
                        element={<CreateJobView />}
                    />
                    <Route
                        path="/jobs-new/create/preset"
                        element={<CreateJobFromPresetView />}
                    />
                    <Route
                        path="/jobs-new/recent"
                        element={<ListRecentJobsView />}
                    />
                    <Route
                        path="/jobs-new/recent/:date"
                        element={<ListRecentJobsView />}
                    />
                    <Route
                        path="/jobs-new/:jobId/edit"
                        element={<UpdateJobView />}
                    />
                    <Route
                        path="/jobs-new/:jobId"
                        element={<ShowJobView />}
                    />
                    <Route
                        path="/jobs-new"
                        element={<ListJobsView />}
                    />
                    <Route
                        path="/laundry"
                        element={<LaundryView />}
                    />
                    <Route
                        path="/messages"
                        element={<MessagesView />}
                    />
                    <Route
                        path="/reservations/summary"
                        element={<ViewReservationsSummary />}
                    />
                    <Route
                        path="/reservations/review"
                        element={<ReviewReservationView />}
                    />
                    <Route
                        path="/reservations/review/:reservationId"
                        element={<ReviewReservationView />}
                    />
                    <Route
                        path="/cleaning/status"
                        element={<DisplayCleaningStatusRedirect />}
                    />
                    <Route
                        path="/cleaning/status/:date"
                        element={<DisplayCleaningStatusView />}
                    />
                    <Route
                        path="/cleaning/plan"
                        element={<PlanCleaningRedirect />}
                    />
                    <Route
                        path="/cleaning/plan/:date"
                        element={<PlanCleaningView />}
                    />
                    <Route
                        path="/cleaning/timeline"
                        element={<DisplayCleaningTimelineRedirect />}
                    />
                    <Route
                        path="/cleaning/timeline/:date"
                        element={<DisplayCleaningTimelineView />}
                    />
                    <Route
                        path="/users/availability/:userGroupId"
                        element={<ViewUserAvailabilityView />}
                    />
                    <Route
                        path='/stayql'
                        element={<ListStayqlQueriesView />}
                    />
                    <Route
                        path="/stayql/create"
                        element={<CreateStayqlQueryView />}
                    />
                    <Route
                        path="/stayql/:stayqlQueryId/edit"
                        element={<EditStayqlQueryView />}
                    />
                    <Route
                        path="/stayql/:stayqlQueryId"
                        element={<ShowStayqlQueryView />}
                    />
                    <Route
                        path="/automation"
                        element={<UpdateAutomationView />}
                    />
                </Routes>
            </Layout.Content>
        </Layout>
    );
}

export default App;
